import React from 'react'
import Typography from '@material-ui/core/Typography'
import { RecoverPasswordForm } from '~/pages/recover-password/form/styles'
import { FormControl, Label, Input, ButtonLink, ButtonLoading } from '~/styles/material'
import { FormProps } from '~/types/pages/recover-password'

export const Form: React.FC<FormProps> = ({ loading, register, handleSubmit }) => {
  return (
    <RecoverPasswordForm component="form" onSubmit={handleSubmit} data-testid="form">
      <Typography variant="h6" component="h1">
        <strong>Redefinição de senha</strong>
      </Typography>
      <Typography variant="body1" className="recover-password__description">
        Enviaremos as instruções de redefinição de senha para seu e-mail.
      </Typography>
      <FormControl>
        <Label htmlFor="RecoverPasswordEmail">E-mail</Label>
        <Input
          type="email"
          name="loginId"
          inputRef={register}
          placeholder="Digite seu e-mail"
          id="RecoverPasswordEmail"
          inputProps={{ 'data-testid': 'inputEmail' }}
        />
      </FormControl>
      <div className="recover-password__actions">
        <ButtonLoading
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          loading={loading}
          data-testid="buttonSubmit"
        >
          Enviar e-mail
        </ButtonLoading>
        <ButtonLink
          size="large"
          color="primary"
          to="/login"
          className="recover-password__back"
          data-testid="backButton"
        >
          <strong>Voltar</strong>
        </ButtonLink>
      </div>
    </RecoverPasswordForm>
  )
}

export default Form
