import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'

export const RedefinePasswordForm = styled(Paper)`
  width: 100%;
  padding: var(--space-large);
  .MuiFormControl-root {
    width: 100%;
  }
  .redefine-password {
    &__description {
      margin: var(--space-small) 0 var(--space-large);
    }
    &__actions {
      margin-top: var(--space-large);
      text-align: center;
      .button-loading {
        width: 100%;
      }
    }
  }
`
