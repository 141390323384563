import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Documents from '~/pages/documents'
import Home from '~/pages/home'
import Login from '~/pages/login'
import RecoverPassword from '~/pages/recover-password'
import RedefinePassword from '~/pages/redefine-password'
import SuccessfulResetPassword from '~/pages/successful-reset-password'
import PrivateRoute from '~/routers/PrivateRoute'
import { Props } from '~/types/routers'

export default function({ history }: Props) {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path="/documentos" title="Documentações" component={Documents} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/recover-password" component={RecoverPassword} />
        <Route exact path="/redefine-password" component={RedefinePassword} />
        <Route exact path="/redefine-password/success" component={SuccessfulResetPassword} />
      </Switch>
    </ConnectedRouter>
  )
}
