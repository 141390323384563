import axios, { AxiosError } from 'axios'
import { SERVICES } from '~/constants/config/server'
import { httpStatus } from '~/lib/axios/middlwares'

const auth = axios.create({
  baseURL: SERVICES.AUTH,
})

auth.interceptors.response.use(undefined, (error: AxiosError<any>) => {
  httpStatus(error.response?.status)

  return Promise.reject(error)
})

export default auth
