import styled from 'styled-components'
import theme from '~/styles/theme'

export const RedefinePassword = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 var(--space-medium);
  .redefine-password {
    &__form {
      width: 100%;
    }
  }
  @media (min-width: ${theme.breakpoints.values.lg}px) {
    .redefine-password {
      &__form {
        max-width: 360px;
      }
    }
  }
`
