import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import theme from '~/styles/theme'

export const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 var(--space-medium);
`

export const Card = styled(Paper)`
  width: 100%;
  padding: var(--space-large);
  text-align: center;
  i {
    margin-bottom: var(--space-medium);
    color: var(--color-green-400);
  }
  p {
    margin: var(--space-small) 0 var(--space-large);
  }
  a {
    width: 100%;
    margin-top: var(--space-small);
  }
  @media (min-width: ${theme.breakpoints.values.lg}px) {
    max-width: 360px;
  }
`
