import { push } from 'connected-react-router'
import { call, put, takeLatest, all } from 'redux-saga/effects'
import api from '~/lib/axios/institution'
import { LoginFailed } from '~/store/ducks/Auth/actions'
import {
  GetLoggedUserInstitutionFailed,
  GetLoggedUserInstitutionSucceeded,
  SetLoggedUserInstitution,
  SetLoggedUserInstitutionRequestStatus,
  setUser,
} from '~/store/ducks/Institution/actions'
import INITIAL_STATE from '~/store/ducks/Institution/state'
import TYPES from '~/store/ducks/Institution/types'
import { setLoading } from '~/store/ducks/Loading/actions'

export function* GetLoggedUserInstitutionRequest() {
  try {
    const [, , , { data }] = yield all([
      put(setLoading(true)),
      put(SetLoggedUserInstitution(INITIAL_STATE.institution)),
      put(SetLoggedUserInstitutionRequestStatus({ loading: true })),
      call(api.get, '/v1/institutions'),
    ])

    const [institution] = data

    if (!institution) {
      throw Error
    }

    const { data: users } = yield call(api.get, `/v1/institutions/${institution.id}/users`)

    const [user] = users
    if (!user) {
      throw Error
    }

    yield all([put(GetLoggedUserInstitutionSucceeded(institution)), put(setUser(user)), put(push('/'))])
  } catch (exception) {
    yield all([
      put(push('/login')),
      put(GetLoggedUserInstitutionFailed(exception.message)),
      put(LoginFailed({ loading: false, error: true, errors: [exception.message] })),
    ])
  } finally {
    yield put(setLoading(false))
  }
}

export default all([takeLatest(TYPES.GET_LOGGED_USER_INSTITUTION_REQUESTED, GetLoggedUserInstitutionRequest)])
