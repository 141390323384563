import React from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '~/lib/toast/styles.scss'

const CloseButton = () => <i className="Toastify__toast-button--close fa fa-times" />

toast.configure({
  hideProgressBar: true,
  bodyClassName: 'color-black',
  closeButton: <CloseButton />,
})
