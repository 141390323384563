import React from 'react'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { ListItemLinkProps } from '~/types/components/ListItemLink'

export default (props: ListItemLinkProps) => {
  const { icon, primary, to, selected } = props

  return (
    <ListItem selected={selected} button component={Link} to={to}>
      <ListItemIcon className="drawer-menu__icon drawer-menu__list-item-icon drawer-menu__space-left">
        <i className={icon} />
      </ListItemIcon>
      <ListItemText className="drawer-menu__text" primary={primary} />
    </ListItem>
  )
}
