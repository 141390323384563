import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ButtonLoadingProgressProps } from '~/types/components/ButtonLoading'

export const ButtonLoadingWrap = styled.div`
  position: relative;
`
export const ButtonLoadingProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: ${({ size }: ButtonLoadingProgressProps) => `-${size / 2}px`}; /* stylelint-disable-line */
  margin-left: ${({ size }: ButtonLoadingProgressProps) => `-${size / 2}px`}; /* stylelint-disable-line */
`
export const ButtonLoadingAction = styled(Button)`
  width: 100%;
`
