import { AxiosRequestConfig } from 'axios'
import { push } from 'connected-react-router'
import { call, put, takeLatest, all, select } from 'redux-saga/effects'
import auth from '~/lib/axios/auth'
import { SetFusionMe, SetToken, LoginSucceeded, LoginFailed, ResetAuthentication } from '~/store/ducks/Auth/actions'
import INITIAL_STATE from '~/store/ducks/Auth/state'
import TYPES from '~/store/ducks/Auth/types'
import { GetLoggedUserInstitutionRequest } from '~/store/ducks/Institution/actions'
import Action from '~/types/lib/typesafe-actions'
import States from '~/types/store/allStates'
import Login from '~/types/validations/login'

export function* LoginRequest(data: Action<Login>) {
  try {
    yield put(SetToken(INITIAL_STATE.token))
    yield put(SetFusionMe(INITIAL_STATE.fusionMe))

    const response = yield call(auth.post, '/login', data.payload)

    yield put(LoginSucceeded(response.data.token, response.data.user))

    yield put(GetLoggedUserInstitutionRequest())
  } catch (exception) {
    yield put(LoginFailed({ loading: false, error: true, errors: [exception.message] }))
  }
}

export function* ValidateRequested() {
  try {
    const state: States = yield select()
    const { token } = state.Auth
    const headers = {
      headers: { Authorization: `JWT ${token}` },
    } as AxiosRequestConfig

    yield call(auth.get, '/jwt/validate', headers)

    yield put(push('/'))
  } catch (exception) {
    yield put(ResetAuthentication())
    yield put(push('/login'))
  }
}

export default all([
  takeLatest(TYPES.LOGIN_REQUESTED, LoginRequest),
  takeLatest(TYPES.VALIDATE_REQUESTED, ValidateRequested),
])
