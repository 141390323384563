import React, { useCallback, useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import DrawerMenu from '~/components/DrawerMenu'
import Header from '~/components/Header'
import { Layout, Main, Container } from '~/layout/private/styles'
import { dark } from '~/styles/theme'
import { PrivateLayoutProps } from '~/types/layout'

const drawerWidthOpen = 240
const drawerWidthClose = 73

export default function({ children, title }: PrivateLayoutProps) {
  const [open, UseOpen] = useState<boolean>(false)

  const HandleDrawerToggle = useCallback(() => {
    UseOpen(!open)
  }, [open])

  return (
    <Layout>
      <CssBaseline />
      <Header drawerwidthopen={drawerWidthOpen} drawerwidthclose={drawerWidthClose} open={open} />
      <ThemeProvider theme={dark}>
        <DrawerMenu
          drawerwidthopen={drawerWidthOpen}
          drawerwidthclose={drawerWidthClose}
          open={open}
          handleDrawerToggle={HandleDrawerToggle}
        />
      </ThemeProvider>
      <Main drawerwidthopen={drawerWidthOpen} drawerwidthclose={drawerWidthClose} open={open} />
      <Container open={open} drawerwidthopen={drawerWidthOpen} drawerwidthclose={drawerWidthClose}>
        <h2 className="title">{title}</h2>
        {children}
      </Container>
    </Layout>
  )
}
