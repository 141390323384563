import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { SERVICES } from '~/constants/config/server'
import { setToken, httpStatus } from './middlwares'

const institution = axios.create({
  baseURL: SERVICES.INSTITUTION,
})

institution.interceptors.request.use((config: AxiosRequestConfig) => {
  const overridedConfig = setToken(config)

  return overridedConfig
})

institution.interceptors.response.use(undefined, (error: AxiosError<any>) => {
  httpStatus(error.response?.status)

  return Promise.reject(error)
})

export default institution
