import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import Admission from '~/store/ducks/Admission/reducer'
import Auth from '~/store/ducks/Auth/reducer'
import Institution from '~/store/ducks/Institution/reducer'
import Loading from '~/store/ducks/Loading/reducer'

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    Admission,
    Auth,
    Institution,
    Loading,
  })

export default createRootReducer
