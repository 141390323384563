import { action } from 'typesafe-actions'
import TYPES from '~/store/ducks/Auth/types'
import Action from '~/types/lib/typesafe-actions'
import { Me as FusionMe } from '~/types/models/Auth'
import RequestStatus from '~/types/utils/RequestStatus'
import Login from '~/types/validations/login'

export const SetFusionMe = (fusionMe: FusionMe | null) => action(TYPES.SET_FUSION_ME, { fusionMe })

export const SetToken = (token: string) => action(TYPES.SET_TOKEN, { token })

export const LoginRequested = (payload: Login): Action<Login> => action<string, Login>(TYPES.LOGIN_REQUESTED, payload)

export const LoginFailed = (loginRequest: RequestStatus<object>) => action(TYPES.LOGIN_FAILED, { loginRequest })

export const LoginSucceeded = (token: string, fusionMe: FusionMe | null) =>
  action(TYPES.LOGIN_SUCCEEDED, { token, fusionMe })

export const ValidateRequested = () => action(TYPES.VALIDATE_REQUESTED)

export const ResetAuthentication = () => action(TYPES.RESET_AUTHENTICATION)
