import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import HeaderBasic from '~/components/HeaderBasic'
import LoginForm from '~/pages/login/form'
import { Login } from '~/pages/login/styles'
import * as AuthActions from '~/store/ducks/Auth/actions'
import States from '~/types/store/allStates'
import RequestStatus from '~/types/utils/RequestStatus'
import LoginFields from '~/types/validations/login'
import validationSchema from '~/validations/pages/login'

interface InstitutionLoginStatus {
  loginRequest: RequestStatus<object>
  institutionRequest: RequestStatus<object>
  token: string
}

export default function() {
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm<LoginFields>({
    validationSchema,
  })
  const { token, loginRequest, institutionRequest } = useSelector<States, InstitutionLoginStatus>(state => ({
    loginRequest: state.Auth.loginRequest || {},
    institutionRequest: state.Institution.loggedUserInstitutionRequestStatus || {},
    token: state.Auth.token,
  }))

  const HandleSubmit = handleSubmit((payload, event) => {
    event!.preventDefault()

    dispatch(AuthActions.LoginRequested(payload))
  })

  useEffect(() => {
    if (token) {
      dispatch(AuthActions.ValidateRequested())
    } else {
      dispatch(AuthActions.ResetAuthentication())
    }
  }, [])

  return (
    <>
      <HeaderBasic />
      <Login className="login" data-testid="login">
        <div className="login__presentation">
          <div className="login__info">
            <Typography variant="h4" component="h1">
              <strong>
                Bem-vindo à<br />
                Quero Educação
              </strong>
            </Typography>
            <Typography variant="body1" component="p">
              Nossa ferramenta te ajuda a aumentar o tráfego no site da sua faculdade, melhorar sua conversão e
              solucionar muitos outros desafios do seu dia a dia.
            </Typography>
          </div>
        </div>
        <div className="login__body">
          <LoginForm
            loading={loginRequest.loading}
            error={loginRequest.error || institutionRequest.error}
            register={register}
            handleSubmit={HandleSubmit}
          />
        </div>
      </Login>
    </>
  )
}
