import { produce } from 'immer'
import { Reducer } from 'redux'
import { INITIAL_STATE } from '~/store/ducks/Admission/state'
import TYPES from '~/store/ducks/Admission/types'
import Action from '~/types/lib/typesafe-actions'
import State from '~/types/store/ducks/Admission/state'

const reducer: Reducer<State, Action<State>> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_ADMISSIONS_REQUESTED:
      return produce(state, (draft: State) => {
        draft.loading = true
        draft.admissions = []
        draft.infos = undefined
      })

    case TYPES.GET_ADMISSIONS_SUCCEEDED:
      return produce(state, (draft: State) => {
        draft.loading = false
        draft.admissions = action.payload.admissions
        draft.infos = action.payload.infos
      })

    case TYPES.GET_ADMISSIONS_FAILED:
      return produce(state, (draft: State) => {
        draft.loading = false
      })

    default:
      return state
  }
}

export default reducer
