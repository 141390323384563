import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import api from '~/lib/axios/admission'
import { setLoading, getAdmissionsFailed, getAdmissionsSuccess } from '~/store/ducks/Admission/actions'
import TYPES from '~/store/ducks/Admission/types'
import Action from '~/types/lib/typesafe-actions'
import States from '~/types/store/allStates'
import { PropsGetAdmissions } from '~/types/store/ducks/Admission/sagas'

interface LooseObject {
  [key: string]: any
}

export function* getAdmissions({ payload }: Action<PropsGetAdmissions>) {
  yield put(setLoading(true))

  const filters: LooseObject = payload || {}
  Object.keys(filters).forEach((key: string) => !filters[key] && delete filters[key])
  try {
    const getInstitution = (state: States) => state.Institution.institution?.id
    const institution = yield select(getInstitution)
    const { data } = yield call(api.get, '/v1/admissions', {
      params: {
        ...filters,
        institution,
      },
    })

    const { admissions, currentPage, total, pages } = data

    yield put(getAdmissionsSuccess({ admissions, infos: { currentPage: Number(currentPage), total, pages } }))
  } catch (err) {
    yield put(getAdmissionsFailed(err))
  }
}

export default all([takeLatest(TYPES.GET_ADMISSIONS_REQUESTED, getAdmissions)])
