import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import HeaderBasic from '~/components/HeaderBasic'
import auth from '~/lib/axios/auth'
import yup from '~/lib/yup'
import Form from '~/pages/redefine-password/form'
import { RedefinePassword } from '~/pages/redefine-password/styles'
import { Alert } from '~/styles/material'
import { Data } from '~/types/pages/redefine-password'
import useQuery from '~/utils/useQuery'

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8)
    .required(),
  confirmPassword: yup
    .string()
    .min(8)
    .required()
    .oneOf([yup.ref('password'), null]),
})

export default function() {
  const [loading, UseLoading] = useState<boolean>(false)
  const [fusionAuthError, UseFusionAuthError] = useState<string>('')
  const { register, handleSubmit, errors } = useForm<Data>({
    validationSchema,
  })
  const changePasswordId = useQuery().get('changePasswordId')
  const history = useHistory()

  const HandleSubmit = handleSubmit(async (payload, event) => {
    event!.preventDefault()

    UseLoading(true)

    try {
      await auth.post(`/user/change-password/${changePasswordId}`, payload)

      history.push('/redefine-password/success')
    } catch (error) {
      const {
        response: { status },
      } = error

      if (status === 404) {
        UseFusionAuthError('Este link para redefinição de senha encontra-se expirado.')
      } else {
        UseFusionAuthError('Algo de errado aconteceu na sua requisição. Tente novamente')
      }
    }

    UseLoading(false)
  })

  return (
    <>
      <HeaderBasic />
      {fusionAuthError && (
        <Alert severity="error" className="login__alert" data-testid="alert">
          {fusionAuthError}
        </Alert>
      )}
      <RedefinePassword className="redefine-password">
        <div className="redefine-password__form">
          <Form register={register} errors={errors} loading={loading} handleSubmit={HandleSubmit} />
        </div>
      </RedefinePassword>
    </>
  )
}
