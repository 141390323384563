import React, { memo } from 'react'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import { Header, ToolbarMenu } from '~/components/Header/styles'
import UserInformation from '~/components/Header/UserInformation'
import { Props } from '~/types/components/Header'

const HeaderComponent: React.FC<Props> = ({ drawerwidthopen, drawerwidthclose, open }) => {
  return (
    <Header position="fixed" open={open} drawerwidthopen={drawerwidthopen} drawerwidthclose={drawerwidthclose}>
      <ToolbarMenu>
        <div className="header__info">
          <IconButton className="header__icon-button-right">
            <i className="fal fa-bell" />
          </IconButton>
          <IconButton className="header__icon-button-right">
            <i className="fal fa-comment-alt" />
          </IconButton>
          <IconButton className="header__icon-button-right">
            <i className="fal fa-question-circle" />
          </IconButton>
        </div>
        <Divider orientation="vertical" flexItem className="header__divider-right" />
        <UserInformation />
      </ToolbarMenu>
    </Header>
  )
}

export default memo(HeaderComponent)
