import React from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import { Input, Placeholder, SingleValue, DropdownIndicator } from '~/components/Autocomplete/styles'
import materialTheme from '~/styles/theme'
import { PropsAutocomplete } from '~/types/components/Autocomplete'

const colourStyles = {
  option: (styles: any, { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean }) => ({
    ...styles,
    color: isFocused || isSelected ? 'white' : 'black',
  }),
}

const Autocomplete = ({ id, options, control, placeholder, name }: PropsAutocomplete) => {
  return (
    <Controller
      as={
        <Select
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral30: 'black',
              primary25: materialTheme.palette.primary.main,
              primary: materialTheme.palette.primary.main,
            },
          })}
          styles={colourStyles}
          isClearable
          noOptionsMessage={() => 'Sem resultado'}
          placeholder={placeholder || 'Selecione'}
          components={{
            Input,
            SingleValue,
            Placeholder,
            DropdownIndicator,
            IndicatorSeparator: () => null,
          }}
        />
      }
      id={id}
      options={options}
      name={name}
      control={control}
    />
  )
}

export default React.memo(Autocomplete)
