import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import HeaderBasic from '~/components/HeaderBasic'
import auth from '~/lib/axios/auth'
import Form from '~/pages/recover-password/form'
import { RecoverPassword } from '~/pages/recover-password/styles'
import { Alert } from '~/styles/material'
import { AlertOptions } from '~/types/components/Alert'
import { Data } from '~/types/pages/recover-password'

const validationSchema = yup.object().shape({
  loginId: yup
    .string()
    .email()
    .required(),
})

export default function() {
  const [loading, UseLoading] = useState<boolean>(false)
  const [alertOptions, UseAlertOptions] = useState<AlertOptions>()
  const { register, handleSubmit } = useForm<Data>({
    validationSchema,
  })

  const HandleSubmit = handleSubmit(async (payload, event) => {
    event!.preventDefault()

    UseLoading(true)

    try {
      await auth.post('/user/forgot-password', payload)
      UseAlertOptions({
        severity: 'success',
        message: 'O link para redefinição de senha foi enviado para seu email!',
      })
    } catch (error) {
      UseAlertOptions({
        severity: 'error',
        message: 'Algo de errado aconteceu na sua requisição. Tente novamente.',
      })
    }

    UseLoading(false)
  })

  return (
    <>
      <HeaderBasic />
      <RecoverPassword className="recover-password">
        <div className="recover-password__form">
          {alertOptions && (
            <Alert severity={alertOptions.severity} className="recover-password__alert" data-testid="alert">
              {alertOptions.message}
            </Alert>
          )}
          <Form register={register} loading={loading} handleSubmit={HandleSubmit} />
        </div>
      </RecoverPassword>
    </>
  )
}
