/* istanbul ignore file */
import * as yup from 'yup'

const fields = {
  loginId: yup.string().required(),
  password: yup.string().required(),
  continue: yup.boolean(),
}

export default yup.object().shape(fields)
