import React from 'react'
import Typography from '@material-ui/core/Typography'
import ButtonLink from '~/components/ButtonLink'
import HeaderBasic from '~/components/HeaderBasic'
import { Main, Card } from '~/pages/successful-reset-password/styles'

export default function() {
  return (
    <>
      <HeaderBasic />
      <Main className="successful-reset-password">
        <Card>
          <i className="fa fa-check" />
          <Typography variant="h6" component="h1">
            <strong>Você redefiniu sua senha</strong>
          </Typography>
          <Typography variant="body1">Utilize essa nova senha sempre que precisar entrar em sua conta.</Typography>
          <ButtonLink variant="contained" size="large" color="primary" to="/login" data-testid="actionButton">
            <strong>Entrar</strong>
          </ButtonLink>
        </Card>
      </Main>
    </>
  )
}
