import { action } from 'typesafe-actions'
import TYPES from '~/store/ducks/Institution/types'
import Institution from '~/types/models/Institution'
import { UserInstitutionResponse } from '~/types/store/ducks/Institution/state'
import RequestStatus from '~/types/utils/RequestStatus'

export const setUser = (user: UserInstitutionResponse) => action(TYPES.SET_USER_INSTITUTION, { user })

export const SetLoggedUserInstitution = (institution: Institution | null) =>
  action(TYPES.SET_LOGGED_USER_INSTITUTION, { institution })

export const SetLoggedUserInstitutionRequestStatus = (loggedUserInstitutionRequestStatus: RequestStatus<object>) =>
  action(TYPES.SET_LOGGED_USER_INSTITUTION_REQUEST_STATUS, { loggedUserInstitutionRequestStatus })

export const GetLoggedUserInstitutionRequest = () => action(TYPES.GET_LOGGED_USER_INSTITUTION_REQUESTED)

export const GetLoggedUserInstitutionFailed = (errorMessage: string) =>
  action(TYPES.GET_LOGGED_USER_INSTITUTION_FAILED, {
    loggedUserInstitutionRequestStatus: {
      errors: [errorMessage],
    },
  })

export const GetLoggedUserInstitutionSucceeded = (institution: Institution | null) =>
  action(TYPES.GET_LOGGED_USER_INSTITUTION_SUCCEEDED, {
    institution,
  })
