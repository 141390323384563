import { red, green } from '@material-ui/core/colors'
import { createMuiTheme, Theme, ThemeOptions } from '@material-ui/core/styles'

const commonThemeConfiguration: ThemeOptions = {
  typography: {
    fontFamily: 'proxima-nova',
  },
  palette: {
    primary: {
      main: '#304ffe',
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        margin: undefined,
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '13.5px 14px',
      },
    },
    MuiButtonBase: {
      root: {
        margin: undefined,
      },
    },
    MuiInputBase: {
      root: {
        height: 48,
      },
    },
    MuiButton: {
      label: {
        fontSize: 'var(--size-font-medium)',
        fontFamily: 'proxima-nova-bold',
        textTransform: 'none',
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: `var(--color-primary)`,
      },
    },
  },
}

const theme: Theme = createMuiTheme({
  ...commonThemeConfiguration,
  palette: {
    primary: {
      main: '#304ffe',
    },
    secondary: {
      main: '#143182',
      light: green[50],
      dark: green[900],
    },
    error: {
      main: red.A400,
      light: red[50],
      dark: red[900],
    },
    background: {
      default: '#fbfbfb',
    },
    success: {
      main: green[700],
      light: green[50],
      dark: green[900],
    },
  },
})

export const dark = createMuiTheme({
  ...commonThemeConfiguration,
  palette: {
    type: 'dark',
  },
})

export default theme
