import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import PrivateLayout from '~/layout/private'
import States from '~/types/store/allStates'

const PrivateRoute = ({ component: Component, title, ...rest }: any) => {
  const isLogged = useSelector<States>(state => state.Auth.isLogged)

  return (
    <Route
      {...rest}
      render={(props: any) =>
        isLogged ? (
          <PrivateLayout title={title}>
            <Component {...props} />
          </PrivateLayout>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute
