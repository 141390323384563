import React from 'react'
import { Container, Loading, Warning } from '~/components/Table/style'
import { Status } from '~/types/components/table'

export default ({ loading, empty }: Status) => {
  if (loading) {
    return (
      <Container data-testid="tableStatusLoading">
        <Loading />
        <span className="message">Carregando...</span>
      </Container>
    )
  }

  if (empty) {
    return (
      <Container data-testid="tableStatusEmpty">
        <Warning />
        <span className="message">Sem informações</span>
      </Container>
    )
  }

  return <></>
}
