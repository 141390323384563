import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import theme from '~/styles/theme'
import { Props } from '~/types/components/Header'

const headerTransitionDefault = theme.transitions.create(['width', 'margin'], {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
})

const headerTransitionOpen = theme.transitions.create(['width', 'margin'], {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.enteringScreen,
})

export const Header = styled(AppBar).attrs({ component: 'header', elevation: 0 })`
  width: ${({ open, drawerwidthopen }: Props) => (!open ? '100%' : `calc(100% - ${drawerwidthopen}px)`)};
  border-bottom: 4px solid var(--color-gray-100);
  margin-left: ${({ open, drawerwidthopen }: Props) => (!open ? 0 : drawerwidthopen)};
  color: var(--color-font-major);
  background: white;
  transition: ${({ open }: Props) => (!open ? headerTransitionDefault : headerTransitionOpen)};
  img {
    border: 3px solid var(--color-primary);
    border-radius: 50%;
  }
  .header {
    &__divider-right {
      display: none;
      width: 2px;
      margin: var(--space-medium) var(--space-large) var(--space-medium) var(--space-medium);
    }
  }
  @media (min-width: ${theme.breakpoints.values.lg}px) {
    .header {
      &__divider-right {
        display: block;
      }
      &__icon-button-right {
        & + .header__icon-button-right {
          margin-left: var(--space-small);
        }
      }
      &__user {
        margin-left: 0;
      }
      &__info {
        display: flex;
      }
    }
  }
`

export const ToolbarMenu = styled(Toolbar)`
  justify-content: flex-end;
`
