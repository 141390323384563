import { Option } from '~/types/pages/documents'

export enum STATUSES {
  PENDING = 'pending',
  PAID = 'paid',
  REGISTERED = 'registered',
  ESCAPE = 'escaped',
}

const options: Option[] = [
  { label: 'Pendente', value: STATUSES.PENDING },
  { label: 'Pago', value: STATUSES.PAID },
  { label: 'Matriculado', value: STATUSES.REGISTERED },
  { label: 'Evadido', value: STATUSES.ESCAPE },
]

export default options
