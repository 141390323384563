import styled from 'styled-components'
import theme from '~/styles/theme'

export const Login = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  background: var(--color-background-primary);
  img {
    max-width: 100px;
    max-height: 40px;
  }
  h1 {
    margin-bottom: var(--space-large);
  }
  > div {
    padding: var(--space-large) var(--space-medium);
  }
  .login {
    &__body {
      width: 100%;
    }
  }
  @media (min-width: ${theme.breakpoints.values.lg}px) {
    flex-direction: row;
    background: none;
    h1 {
      margin-bottom: var(--space-jumbo);
    }
    > div {
      flex: 1;
    }
    .login {
      &__info {
        max-width: 360px;
      }
      &__body,
      &__presentation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &__presentation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        background: var(--color-background-primary);
      }
    }
  }
`
