import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '~/components/Table/body'
import TableHead from '~/components/Table/head'
import TableStatus from '~/components/Table/status'
import { Paper, TableContainer } from '~/components/Table/style'
import TablePagination from '~/styles/material/TablePagination'
import { PropsTable } from '~/types/components/table'

const CustomTable = ({
  columns,
  rows,
  page,
  count,
  onChangePagination,
  loading,
  rowsPerPage = 5,
  propertyKey = 'id',
}: PropsTable) => {
  const [pageCount, setPageCount] = useState<number>(0)

  useEffect(() => {
    const numberPages = count && count > 0 ? Math.ceil(Number(count) / Number(rowsPerPage)) : 0
    setPageCount(numberPages)
  }, [count, rowsPerPage])

  return (
    <>
      <TableContainer data-testid="tableContainer" component={Paper}>
        <Table data-testid="table" size="medium">
          <TableHead data-testid="tableHead" columns={columns} />
          <TableBody propertyKey={propertyKey} rows={rows} columns={columns} />
        </Table>
        <TableStatus loading={loading} empty={!rows.length} />
        {!!pageCount && (
          <TablePagination
            data-testid="pagination"
            count={pageCount}
            page={page}
            onChange={(_: React.ChangeEvent, currentPage: number) =>
              onChangePagination && onChangePagination(currentPage)
            }
            color="primary"
            shape="rounded"
            variant="outlined"
          />
        )}
      </TableContainer>
    </>
  )
}

export default React.memo(CustomTable)
