import styled from 'styled-components'
import theme from '~/styles/theme'

export const Title = styled.span`
  width: 100%;
  padding-left: var(--space-small);
  color: var(--color-gray-500);
  font-weight: bold;
  font-size: 19px;
`

export const Container = styled.form`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: var(--space-medium);
`

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: var(--space-small);

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    width: 28%;
  }
`

export const GridButton = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-height: 83px;
  padding: var(--space-small);
  margin-top: 0px;
  button {
    width: 100%;
  }

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    align-items: flex-end;
    width: 16%;
    button {
      margin-top: 16px;
    }
  }
`
