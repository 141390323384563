import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { LoginForm } from '~/pages/login/form/styles'
import { FormControl, Label, Input, Alert, Link, ButtonLoading } from '~/styles/material'
import { FormProps } from '~/types/pages/login'

const Form: React.FC<FormProps> = ({ loading, error, register, handleSubmit }) => {
  return (
    <LoginForm className="login__form">
      {error && (
        <Alert severity="error" className="login__alert" data-testid="alert">
          Informações de login inválidas. Verifique e tente novamente ou redefina sua senha
        </Alert>
      )}
      <Paper component="form" onSubmit={handleSubmit} data-testid="form">
        <Typography variant="h6" component="h3">
          <strong>Entrar com a minha conta</strong>
        </Typography>
        <FormControl>
          <Label htmlFor="LoginEmail">E-mail</Label>
          <Input
            name="loginId"
            type="email"
            placeholder="Entre com o seu e-mail"
            id="LoginEmail"
            inputRef={register}
            inputProps={{
              'data-testid': 'inputEmail',
            }}
          />
        </FormControl>
        <FormControl>
          <Label htmlFor="LoginPassword">Senha</Label>
          <Input
            name="password"
            type="password"
            placeholder="Digite sua senha"
            id="LoginPassword"
            inputRef={register}
            inputProps={{
              'data-testid': 'inputPassword',
            }}
          />
        </FormControl>
        <div className="login__options form-control">
          <Link
            variant="body1"
            className="login__recover-password"
            to="/recover-password"
            data-testid="recoverPasswordLink"
          >
            <strong>Redefinir senha</strong>
          </Link>
        </div>
        <div className="form-control">
          <ButtonLoading
            loading={loading}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            data-testid="buttonSubmit"
          >
            Entrar
          </ButtonLoading>
        </div>
      </Paper>
    </LoginForm>
  )
}

export default Form
