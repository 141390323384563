import React, { useState, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { Container, Information } from '~/components/Header/UserInformation/styles'
import { SetToken } from '~/store/ducks/Auth/actions'
import { Me } from '~/types/models/Auth'
import States from '~/types/store/allStates'
import { UserInstitutionResponse } from '~/types/store/ducks/Institution/state'

export default function UserInformations() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const dispatch = useDispatch()
  const institutionUser = useSelector<States, UserInstitutionResponse | null>(state => state.Institution.user)
  const fusionMe = useSelector<States, Me | null>(state => state.Auth.fusionMe)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(SetToken(''))
  }

  return (
    <Container>
      <Information>
        <Typography variant="body1" component="span">
          <strong data-testid="header-name">{institutionUser?.user.name}</strong>
        </Typography>
        <Typography data-testid="header-role" variant="caption" component="span">
          {institutionUser?.department}
        </Typography>
      </Information>
      <IconButton color="primary" onClick={handleClick}>
        <Avatar data-testid="header-avatar" src={fusionMe?.imageUrl} alt="Foto do usuário" />
      </IconButton>
      <Menu
        data-testid="header-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem data-testid="header-menu-logout" onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </Container>
  )
}
