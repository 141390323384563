import styled from 'styled-components'
import MuiCircularProgress from '@material-ui/core/CircularProgress'
import MuiPaper from '@material-ui/core/Paper'
import MuiTableCell from '@material-ui/core/TableCell'
import MuiTableContainer from '@material-ui/core/TableContainer'
import { ContainerProps } from '~/types/components/table'

export const Paper = styled(MuiPaper).attrs({ elevation: 0 })`
  max-width: 100vw;
`

export const TableContainer = styled(MuiTableContainer)`
  max-height: ${({ maxHeight }: ContainerProps) => maxHeight || 'auto'};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  .message {
    margin-top: 16px;
    color: var(--color-gray-500);
  }
`

export const Loading = styled(MuiCircularProgress)`
  width: 100%;
`

export const Warning = styled.i.attrs({ className: 'far fa-exclamation-triangle' })`
  color: var(--color-secondary);
  font-size: 40px !important;
`

export const TableCell = styled(MuiTableCell)`
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid var(--color-gray-050);
  border-bottom: 1px solid var(--color-gray-050);
  color: var(--gray-500);
  font-weight: 900;
  font-size: var(--size-font-medium);
  background: white;
`

export const TableCellBody = styled(MuiTableCell)`
  border-bottom: 1px solid var(--color-gray-050);
  color: var(--gray-500);
  font-size: var(--size-font-medium);
`
