import styled from 'styled-components'
import MuiButton from '@material-ui/core/Button'
import MuiCheckbox from '@material-ui/core/Checkbox'
import MuiFormControl from '@material-ui/core/FormControl'
import MuiTextField from '@material-ui/core/TextField'
import MuiAlert from '@material-ui/lab/Alert'
import ButtonLinkComponent from '~/components/ButtonLink'
import { ButtonLoading as ButtonLoadingCircle } from '~/components/ButtonLoading'
import LinkComponent from '~/components/Link'
import StyledTablePagination from '~/styles/material/TablePagination'

export const Link = LinkComponent
export const ButtonLink = ButtonLinkComponent
export const ButtonLoading = ButtonLoadingCircle
export const FormControl = styled(MuiFormControl)`
  border: 0;
  & + .MuiFormControl-root {
    margin-top: var(--space-medium);
  }
`
export const Label = styled.label`
  font-size: var(--size-font-small);
  font-family: proxima-nova-bold, sans-serif;
  text-transform: uppercase;
  & + .MuiInput-formControl {
    margin-top: 0;
  }
`
export const Input = styled(MuiTextField).attrs({
  variant: 'outlined',
  className: '.form-control__input',
})`
  background: white;
`
export const Checkbox = styled(MuiCheckbox).attrs({ className: 'form-control__checkbox' })``
export const Alert = styled(MuiAlert)`
  font-size: var(--size-font-medium);
  .MuiAlert-icon {
    display: none;
  }
`

export const Button = styled(MuiButton).attrs({
  color: 'primary',
  variant: 'contained',
})`
  height: 48px;
`
export const TablePagination = StyledTablePagination
