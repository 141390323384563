import React from 'react'
import { ButtonLoadingWrap, ButtonLoadingProgress, ButtonLoadingAction } from '~/components/ButtonLoading/styles'
import { ButtonLoadingProps, Sizes } from '~/types/components/ButtonLoading'

export const ButtonLoading: React.FC<ButtonLoadingProps> = ({
  children,
  loading,
  size,
  disabled,
  className,
  variant,
  ...props
}) => {
  const sizes: Sizes = {
    small: 12,
    medium: 14,
    large: 16,
  }

  const loadingSize = size ? sizes[size] : sizes.small
  const buttonDisabled = typeof disabled === 'boolean' ? disabled : loading

  return (
    <ButtonLoadingWrap className="button-loading" data-testid="buttonLoadingWrap">
      <ButtonLoadingAction
        disabled={buttonDisabled}
        size={size}
        variant={variant}
        className={`button-loading__button ${className}`}
        data-testid="buttonLoadingAction"
        {...props}
      >
        {children}
      </ButtonLoadingAction>
      {loading && (
        <ButtonLoadingProgress
          className="button-loading__loading"
          size={loadingSize}
          data-testid="buttonLoadingProgress"
        />
      )}
    </ButtonLoadingWrap>
  )
}

export default ButtonLoading
