import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '~/components/Table'
import STATUSES from '~/constants/STATUSES'
import Filter from '~/pages/documents/filter'
import { Container } from '~/pages/documents/styles'
import { getAdmissionsRequest } from '~/store/ducks/Admission/actions'
import { FilterFields } from '~/types/pages/documents'
import States from '~/types/store/allStates'
import { PropsGetAdmissions } from '~/types/store/ducks/Admission/sagas'
import AdmissionInitialState from '~/types/store/ducks/Admission/state'

const columns = [
  {
    id: 'admissionStatus',
    label: 'Status',
    format: (val: string) => STATUSES.find(status => status.value === val)?.label,
  },
  { id: 'student', label: 'Aluno' },
  { id: 'cpf', label: 'CPF' },
  { id: 'levelName', label: 'Ano/Série' },
  { id: 'shiftName', label: 'Turno' },
]

export default function() {
  const rowsPerPage = 5
  const dispatch = useDispatch()
  const [filters, setFilters] = useState<FilterFields>({})
  const { loading, admissions, infos } = useSelector<States, AdmissionInitialState>(state => state.Admission)
  const onChange = (payload: FilterFields) => {
    setFilters(payload)
    const params: PropsGetAdmissions = payload
    params.page = 1
    params.perPage = rowsPerPage
    dispatch(getAdmissionsRequest(params))
  }

  const onChangePagination = (page: number) => {
    const params: PropsGetAdmissions = filters
    params.page = page
    params.perPage = rowsPerPage
    dispatch(getAdmissionsRequest(params))
  }

  useEffect(() => {
    const params: PropsGetAdmissions = { perPage: rowsPerPage }
    dispatch(getAdmissionsRequest(params))
  }, [])

  return (
    <Container>
      <Filter onChange={onChange} />
      <Table
        page={infos?.currentPage}
        loading={loading}
        count={infos?.total}
        propertyKey="admissionId"
        rowsPerPage={rowsPerPage}
        rows={admissions}
        columns={columns}
        onChangePagination={onChangePagination}
      />
    </Container>
  )
}
