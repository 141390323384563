import React from 'react'
import { hot } from 'react-hot-loader/root'
import { Provider } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { PersistGate } from 'redux-persist/integration/react'
import Loading from '~/components/Loading'
import Routers from '~/routers/index'
import { store, persistor } from '~/store'
import GlobalStyles from '~/styles/global'
import theme from '~/styles/theme'
import history from '~/utils/history'
import '~/lib/toast'

function App() {
  return (
    <>
      <Provider store={store}>
        <Loading />
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles />
            <Routers history={history} />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </>
  )
}

export default hot(App)
