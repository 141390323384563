import React from 'react'
import LogoQueroEducacao from '~/assets/images/logo-quero-educacao.png'
import { HeaderBasic } from '~/components/HeaderBasic/styles'

export default function() {
  return (
    <HeaderBasic className="header-basic">
      <img src={LogoQueroEducacao} alt="Logo da Quero Educação" />
    </HeaderBasic>
  )
}
