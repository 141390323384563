import React from 'react'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'
import { RedefinePasswordForm } from '~/pages/redefine-password/form/styles'
import { FormControl, Label, Input, ButtonLoading } from '~/styles/material'
import { FormProps } from '~/types/pages/redefine-password'

export const Form: React.FC<FormProps> = ({ register, loading, errors, disabled, handleSubmit }) => {
  return (
    <RedefinePasswordForm component="form" onSubmit={handleSubmit} data-testid="form">
      <Typography variant="h6" component="h1">
        <strong>Redefinição de senha</strong>
      </Typography>
      <Typography variant="body1" className="redefine-password__description">
        Sua nova senha precisa ter no mínimo 8 caracteres.
      </Typography>
      <FormControl error={!!errors?.password}>
        <Label htmlFor="RecoverPasswordPassword">Nova senha</Label>
        <Input
          type="password"
          name="password"
          placeholder="Digite sua nova senha"
          id="RecoverPasswordPassword"
          inputRef={register}
          inputProps={{ 'data-testid': 'password' }}
        />
        <FormHelperText>{errors?.password?.message}</FormHelperText>
      </FormControl>
      <FormControl error={!!errors?.confirmPassword}>
        <Label htmlFor="RecoverPasswordConfirmPassword">Confirmar nova senha</Label>
        <Input
          type="password"
          name="confirmPassword"
          placeholder="Repita sua nova senha"
          id="RecoverPasswordConfirmPassword"
          inputRef={register}
          inputProps={{ 'data-testid': 'confirmPassword' }}
        />
        {errors?.confirmPassword && (
          <FormHelperText>
            {errors?.confirmPassword?.type === 'oneOf'
              ? 'As senhas estão diferentes.'
              : errors?.confirmPassword?.message}
          </FormHelperText>
        )}
      </FormControl>
      <div className="redefine-password__actions">
        <ButtonLoading
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          loading={loading}
          disabled={disabled}
          data-testid="buttonSubmit"
        >
          Redefinir minha senha
        </ButtonLoading>
      </div>
    </RedefinePasswordForm>
  )
}

export default Form
