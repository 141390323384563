import { produce } from 'immer'
import { Reducer } from 'redux'
import { INITIAL_STATE } from '~/store/ducks/Auth/state'
import TYPES from '~/store/ducks/Auth/types'
import Action from '~/types/lib/typesafe-actions'
import State from '~/types/store/ducks/Auth/state'

const reducer: Reducer<State, Action<State>> = (state = INITIAL_STATE, action) => {
  const setFusionMe = (draft: State) => {
    draft.fusionMe = action.payload.fusionMe
  }
  const setToken = (draft: State) => {
    const { token } = action.payload

    draft.token = token
    draft.isLogged = !!token

    localStorage.setItem('token', token)
  }

  switch (action.type) {
    case TYPES.SET_FUSION_ME:
      return produce(state, setFusionMe)

    case TYPES.SET_TOKEN:
      return produce(state, setToken)

    case TYPES.LOGIN_REQUESTED:
      return produce(state, (draft: State) => {
        draft.loginRequest = {
          loading: true,
          error: false,
          errors: [],
        }
      })

    case TYPES.LOGIN_SUCCEEDED:
      return produce(state, (draft: State) => {
        draft.loginRequest = {
          loading: false,
          error: false,
          errors: [],
        }

        setToken(draft)
        setFusionMe(draft)
      })

    case TYPES.LOGIN_FAILED:
      const { error, errors } = action.payload.loginRequest

      return produce(state, (draft: State) => {
        localStorage.clear()

        draft.fusionMe = INITIAL_STATE.fusionMe
        draft.loginRequest = {
          loading: false,
          error,
          errors,
        }
      })

    case TYPES.VALIDATE_REQUESTED:
      return produce(state, (draft: State) => {
        draft.validateRequest = {
          loading: true,
          error: false,
          errors: [],
        }
      })

    case TYPES.RESET_AUTHENTICATION:
      localStorage.clear()

      return produce(state, (draft: State) => {
        draft.token = INITIAL_STATE.token
        draft.fusionMe = INITIAL_STATE.fusionMe
        draft.loginRequest = INITIAL_STATE.loginRequest
        draft.validateRequest = INITIAL_STATE.validateRequest
      })

    default:
      return state
  }
}

export default reducer
