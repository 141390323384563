import { components } from 'react-select'
import styled from 'styled-components'

export const Input: any = styled(components.Input).attrs({
  'data-testid': 'reactSelectInput',
})`
  padding: 7px;
`

export const Placeholder = styled(components.Placeholder)`
  padding-left: 7px;
  font-size: var(--size-font-medium);
  font-family: var(--font-family-default);
  opacity: 0.72;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`

export const SingleValue = styled(components.SingleValue)`
  padding-left: 7px;
`

export const DropdownIndicator = styled(components.DropdownIndicator)`
  svg {
    color: var(--color-primary);
  }
`
