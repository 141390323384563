import { toast } from 'react-toastify'
import { AxiosRequestConfig } from 'axios'
import history from '~/utils/history'

export const httpStatus = (status?: number) => {
  if (status === 401) {
    toast.warn('Ops! Sua sessão expirou! Faça o login novamente.')

    history.push('/login')
  }

  if (status === 500) {
    toast.error('Ocorreu algo de errado. Tente novamente.')
  }
}

export const setToken = (config: AxiosRequestConfig) => {
  const token: string | null = localStorage.getItem('token')

  if (!token) {
    toast.error('Você precisa estar logado para acessar essa página.')

    history.push('/login')
  }

  const overridedConfig = config

  overridedConfig.headers.Authorization = token

  return overridedConfig
}
