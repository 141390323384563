import State from '~/types/store/ducks/Admission/state'

export const INITIAL_STATE: State = {
  errors: [],
  admissions: [],
  infos: undefined,
  loading: false,
}

export default INITIAL_STATE
