import React from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import { TableCellBody } from '~/components/Table/style'
import { Column, PropsBody } from '~/types/components/table'

const RowComponent = ({ rows, columns, propertyKey }: PropsBody) => {
  const renderData = (column: Column, value: string | number) => {
    if (column.format) {
      return column.format(value)
    }
    if (column.component) {
      return column.component()
    }
    return value
  }

  return (
    <TableBody data-testid="tableBody">
      {rows.map(row => (
        <TableRow hover tabIndex={-1} key={row[propertyKey]}>
          {columns.map((column: Column) => (
            <TableCellBody key={column.id} align={column.align} style={column.style}>
              {renderData(column, row[column.id])}
            </TableCellBody>
          ))}
        </TableRow>
      ))}
    </TableBody>
  )
}

export default React.memo(RowComponent)
