import { produce } from 'immer'
import { Reducer } from 'redux'
import { INITIAL_STATE } from '~/store/ducks/Institution/state'
import TYPES from '~/store/ducks/Institution/types'
import Action from '~/types/lib/typesafe-actions'
import State from '~/types/store/ducks/Institution/state'

const reducer: Reducer<State, Action<State>> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_LOGGED_USER_INSTITUTION:
      return produce(state, (draft: State) => {
        draft.institution = action.payload.institution
      })

    case TYPES.SET_LOGGED_USER_INSTITUTION_REQUEST_STATUS:
      const { loading, error, errors } = action.payload.loggedUserInstitutionRequestStatus

      return produce(state, (draft: State) => {
        draft.loggedUserInstitutionRequestStatus = {
          loading,
          error: loading ? false : error,
          errors: loading ? [] : errors,
        }
      })

    case TYPES.GET_LOGGED_USER_INSTITUTION_FAILED:
      return produce(state, (draft: State) => {
        draft.institution = null
        draft.loggedUserInstitutionRequestStatus = {
          ...action.payload.loggedUserInstitutionRequestStatus,
          loading: false,
          error: true,
        }
      })

    case TYPES.GET_LOGGED_USER_INSTITUTION_SUCCEEDED:
      return produce(state, (draft: State) => {
        draft.institution = action.payload.institution
        draft.loggedUserInstitutionRequestStatus = {
          loading: false,
          error: false,
          errors: [],
        }
      })

    case TYPES.SET_USER_INSTITUTION:
      return produce(state, (draft: State) => {
        draft.user = action.payload.user
        draft.loggedUserInstitutionRequestStatus = {
          loading: false,
          error: false,
          errors: [],
        }
      })

    default:
      return state
  }
}

export default reducer
