import styled from 'styled-components'
import MuiPagination from '@material-ui/lab/Pagination'

const TablePagination = styled(MuiPagination)`
  .MuiPaginationItem-root.Mui-disabled:disabled {
    border-color: var(--color-gray-900);
    color: var(--color-gray-900);
    font-weight: bold;
    font-size: 16px;
    background-color: var(--color-gray-500);
  }
  .MuiPagination-ul {
    display: flex;
    justify-content: flex-end;
    margin: var(--space-large);
  }
  .MuiPaginationItem-root.Mui-selected {
    color: white;
    font-weight: bold;
    font-size: 16px;
    background: var(--color-primary);
  }
  .MuiPaginationItem-outlined {
    border-color: var(--color-primary);
    margin: 0 4px;
    color: var(--color-primary);
    font-weight: bold;
    font-size: 16px;
  }
  .MuiPaginationItem-ellipsis {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 32px;
    height: 32px;
    padding-bottom: 4px;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    color: var(--color-primary);
    font-weight: bold;
    font-size: 16px;
  }
`
export default TablePagination
