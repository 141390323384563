import styled from 'styled-components'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import theme from '~/styles/theme'
import { Props } from '~/types/components/DrawerMenu'

const headerTransitionDefault = theme.transitions.create('width', {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
})

const headerTransitionOpen = theme.transitions.create('width', {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.enteringScreen,
})

export const DrawerMenu = styled(Drawer).attrs({ component: 'nav' })`
  flex-shrink: 0;
  overflow-x: ${({ open }: Props) => (!open ? 'hidden' : undefined)};
  width: ${({ open, drawerwidthopen, drawerwidthclose }: Props) => `${!open ? drawerwidthclose : drawerwidthopen}px`};
  transition: ${({ open }: Props) => (!open ? headerTransitionDefault : headerTransitionOpen)};
  i {
    color: white;
  }
  img {
    width: var(--size-large);
  }
  .drawer-menu {
    &__toolbar {
      padding-top: var(--space-small);
    }
    &__icon-action {
      margin-left: var(--space-small);
    }
    &__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      margin-top: var(--space-small);
    }
    &__divider {
      width: auto;
      height: 2px;
      margin: var(--space-small) var(--space-medium);
    }
    &__icon {
      margin-top: var(--space-small);
      margin-bottom: var(--space-small);
    }
    &__list-item-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 32px;
      height: 24px;
      margin-right: var(--space-large);
    }
    &__title {
      display: block;
      margin: var(--space-large) var(--space-medium) var(--space-small) var(--space-medium);
      line-height: var(--size-large);
      text-align: center;
    }
    &__title,
    &__list-item,
    &__text {
      overflow: hidden;
      white-space: nowrap;
    }
    &__space-left {
      margin-left: calc(var(--space-micro) + var(--space-micro));
    }
  }
  &.drawer-menu {
    &--open {
      svg {
        width: 100%;
        max-width: 120px;
        min-height: 100%;
        fill: white;
      }
      .drawer-menu {
        &__title {
          text-align: left;
        }
        &__icon {
          margin-top: var(--space-small);
          margin-bottom: var(--space-small);
        }
      }
    }
  }
`

export const Wrap = styled.div`
  width: ${({ open, drawerwidthopen, drawerwidthclose }: Props) => `${!open ? drawerwidthclose : drawerwidthopen}px`};
  transition: ${({ open }: Props) => (!open ? headerTransitionDefault : headerTransitionOpen)};
`

export const ToolbarMenu = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${theme.spacing(0, 1)};
`

export const IconButtonMenu = styled(IconButton)``
