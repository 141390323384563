/* eslint-disable indent */
import { memo } from 'react'
import styled from 'styled-components'
import theme from '~/styles/theme'
import { MainProps } from '~/types/pages/layout'

const mainTransitionDefault = theme.transitions.create('paddingLeft', {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.leavingScreen,
})

const mainTransitionOpen = theme.transitions.create('paddingLeft', {
  easing: theme.transitions.easing.sharp,
  duration: theme.transitions.duration.enteringScreen,
})

const drawerPadding = (width: number, mobile?: boolean): string => {
  return `${width + (mobile ? 0 : 32)}px`
}

export const Layout = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Container = styled.div`
  height: 100%;
  padding-top: var(--space-extra-large);
  padding-right: 0;
  padding-left: ${({ open, drawerwidthopen, drawerwidthclose }: MainProps) =>
    `${!open ? drawerPadding(drawerwidthclose, true) : drawerPadding(drawerwidthopen, true)}`};
  background: var(--color-gray-000);
  transition: ${({ open }: MainProps) => (!open ? mainTransitionDefault : mainTransitionOpen)};
  .title {
    padding-left: var(--space-small);
    margin-bottom: var(--space-medium);
    color: var(--color-gray-500);
    font-weight: bold;
    font-size: var(--size-font-extra-large);
  }
  @media (min-width: ${theme.breakpoints.values.lg}px) {
    padding-right: var(--space-extra-large);
    padding-left: ${({ open, drawerwidthopen, drawerwidthclose }: MainProps) =>
      `${!open ? drawerPadding(drawerwidthclose) : drawerPadding(drawerwidthopen)}`};
  }
`

export const Main = memo(styled.main`
  padding-top: 64px;
  padding-left: ${({ open, drawerwidthopen, drawerwidthclose }: MainProps) =>
    `${!open ? drawerwidthclose : drawerwidthopen}px`};
  transition: ${({ open }: MainProps) => (!open ? mainTransitionDefault : mainTransitionOpen)};
`)
