import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'

export const HeaderBasic = styled(Paper).attrs({ component: 'header', elevation: 1 })`
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: var(--space-medium) 0;
  border-radius: 0;
  img {
    max-width: 100px;
    max-height: 40px;
  }
`
