import State from '~/types/store/ducks/Auth/state'

export const INITIAL_STATE: State = {
  token: '',
  fusionMe: null,
  loginRequest: { loading: false, error: false, errors: [] },
  isLogged: false,
  validateRequest: { loading: false, error: false, errors: [] },
}

export default INITIAL_STATE
