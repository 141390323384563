import React from 'react'
import { useForm } from 'react-hook-form'
import Autocomplete from '~/components/Autocomplete'
import STATUSES_OPTIONS from '~/constants/STATUSES'
import { Container, Grid, GridButton, Title } from '~/pages/documents/filter/styles'
import { Label, Input, Button } from '~/styles/material'
import { PropsFilter } from '~/types/pages/documents'

export default function({ onChange }: PropsFilter) {
  const { handleSubmit, control, register } = useForm()

  const HandleSubmit = handleSubmit((payload, event) => {
    event!.preventDefault()
    const { status } = payload
    onChange({ ...payload, status: status?.value })
  })

  return (
    <Container data-testid="documentFilters" onSubmit={HandleSubmit}>
      <Title>Filtrar por</Title>
      <Grid>
        <Label>Nome do aluno</Label>
        <Input
          inputProps={{
            'data-testid': 'name',
          }}
          disabled
          inputRef={register}
          name="name"
          placeholder="Digite o nome do aluno"
        />
      </Grid>
      <Grid>
        <Label>CPF do aluno</Label>
        <Input
          inputProps={{
            'data-testid': 'documentNumber',
          }}
          disabled
          inputRef={register}
          name="documentNumber"
          placeholder="Digite o CPF do aluno"
        />
      </Grid>
      <Grid>
        <Label>Status</Label>
        <Autocomplete name="status" control={control} options={STATUSES_OPTIONS} placeholder="Selecione um status" />
      </Grid>
      <GridButton>
        <Button data-testid="buttonSubmit" type="submit" color="primary" variant="contained">
          Filtrar
        </Button>
      </GridButton>
    </Container>
  )
}
