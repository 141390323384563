import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import LogoIconQuero from '~/assets/images/logo-avatar-light.png'
import LogoQuero from '~/assets/images/logo-pb.svg'
import { DrawerMenu, Wrap, ToolbarMenu, IconButtonMenu } from '~/components/DrawerMenu/styles'
import ListItemLink from '~/components/ListItemLink'
import ITEMS_MENU from '~/constants/ITEMS_MENU'
import { Props } from '~/types/components/DrawerMenu'

const DrawerMenuComponent: React.FC<Props> = ({ drawerwidthopen, drawerwidthclose, open, handleDrawerToggle }) => {
  const history = useHistory()
  const classes = clsx({
    'drawer-menu': true,
    'drawer-menu--open': open,
  })

  return (
    <DrawerMenu
      variant="permanent"
      open={open}
      drawerwidthopen={drawerwidthopen}
      drawerwidthclose={drawerwidthclose}
      className={classes}
      data-testid="drawerMenu"
    >
      <Wrap open={open} drawerwidthopen={drawerwidthopen} drawerwidthclose={drawerwidthclose}>
        <ToolbarMenu className="drawer-menu__toolbar">
          <IconButtonMenu
            onClick={handleDrawerToggle}
            className="drawer-menu__icon-action"
            data-testid="iconButtonMenu"
          >
            {!open ? <i className="fa fa-arrow-right" /> : <i className="fa fa-arrow-left" />}
          </IconButtonMenu>
        </ToolbarMenu>
        <div className="drawer-menu__logo">
          {open ? <LogoQuero /> : <img src={LogoIconQuero} alt="Logo da Quero" />}
        </div>
        <List>
          <ListItemLink
            to="/"
            primary="Página Inicial"
            icon="fal fa-home"
            selected={history.location.pathname === '/'}
          />
        </List>
        <Divider className="drawer-menu__divider" />
        <Typography variant="subtitle2" component="span" className="drawer-menu__title">
          <strong>{open ? 'MATRÍCULA DIGITAL' : 'MD'}</strong>
        </Typography>
        <List>
          {ITEMS_MENU.map(item => (
            <ListItemLink
              key={item.text}
              to={item.to}
              primary={item.text}
              icon={item.icon}
              selected={history.location.pathname === item.to}
            />
          ))}
        </List>
      </Wrap>
    </DrawerMenu>
  )
}

export default memo(DrawerMenuComponent)
