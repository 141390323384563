import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import humps from 'humps'
import { SERVICES } from '~/constants/config/server'
import { httpStatus, setToken } from '~/lib/axios/middlwares'

const admission = axios.create({
  baseURL: SERVICES.ADMISSION,
  transformResponse: data => {
    let response = JSON.parse(data)

    if (response) {
      response = humps.camelizeKeys(response)
    }

    return response
  },
})

admission.interceptors.request.use((config: AxiosRequestConfig) => {
  const overridedConfig = setToken(config)

  return overridedConfig
})

admission.interceptors.response.use(undefined, (error: AxiosError<any>) => {
  httpStatus(error.response?.status)

  return Promise.reject(error)
})

export default admission
