import styled from 'styled-components'
import theme from '~/styles/theme'

export const LoginForm = styled.div`
  width: 100%;
  h3 {
    margin-bottom: var(--space-large);
  }
  button {
    width: 100%;
  }
  form {
    padding: var(--space-large);
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .login__recover-password {
    display: block;
    margin-top: var(--space-small);
  }
  .login {
    &__alert {
      margin-bottom: var(--space-medium);
    }
    &__options {
      margin: var(--space-medium) 0;
    }
  }
  @media (min-width: ${theme.breakpoints.values.lg}px) {
    max-width: 360px;
    .login__recover-password {
      margin-top: 0;
    }
    .login {
      &__options {
        a {
          margin-top: 0;
        }
        .MuiFormControl-root {
          width: auto;
        }
      }
    }
  }
`
