import { createGlobalStyle } from 'styled-components'
import '@quero/zilla-dictionary/build/web/default/tokens.scss'
import '../lib/fontawesome/fontawesome.css'
import '../lib/fontawesome/fontawesome-brands.css'
import './fonts.scss'

export default createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    padding: 0;
    outline: 0;
    margin: 0;
  }
  html {
    display: table;
  }
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  #root {
    display: flex;
    flex-direction: column;
  }
  body[data-theme] {
    color: var(--color-font-major);
    font-family: var(--font-family-default);
    background: #fbfbfb;
  }
  i {
    color: var(--color-primary);
  }
  hr {
    width: 100%;
    border-top: 1px solid var(--color-alpha-gray-016);
  }
  strong {
    font-family: proxima-nova-bold, sans-serif;
  }
  img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
  input::placeholder {
    font-family: var(--font-family-default);
  }
  .fa,
  .fal,
  .far,
  .fab,
  .fas {
    font-weight: normal;
    font-size: var(--size-icon-large);
  }
  .fa,
  .fal {
    font-weight: 600;
    font-family: 'Font Awesome Light Regular', sans-serif;
  }
  .fas {
    font-family: 'Font Awesome Solid Regular', sans-serif;
  }
  .far {
    font-family: 'Font Awesome Solid Regular', sans-serif;
  }
  .fab {
    font-family: 'Font Awesome Brands Regular', sans-serif;
  }
  .container {
    max-width: var(--size-screen-extra-large);
    padding: 0 16px;
    margin: 0 auto;
  }
`
