import styled from 'styled-components'
import theme from '~/styles/theme'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: var(--space-small);
`

export const Information = styled.div`
  display: none;
  padding-right: var(--space-medium);
  text-align: right;
  span {
    display: block;
  }

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    display: block;
  }
`
